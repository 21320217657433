import React from 'react';
import InformationPage from "../components/InformationPage";
import {TypographyStyled} from '../components/CarPostList';


export const emailAddress = "autoshqipinfo@gmail.com";
export const mailTo = "mailto:" + emailAddress;

const Contact: React.FC = () => {
    document.title = "Kontakto - AutoShqip"

    return (
        <InformationPage>
            <h2>Na kontaktoni</h2>
            <TypographyStyled>

                Për çdo informacion, shqetësim apo vërejtje mund të na kontaktoni në faqen e Instagramit (ndodhet më poshtë) ose në e-mail:
                <br/>
                <br/>
                <a href={mailTo}>
                    <strong>{emailAddress}</strong>
                </a>
                <br/>
                <br/>
                Mirëpresim cdo sugjerim apo kontaktim tuajin.
                <br/>
                <br/>
                Ju falenderojmë.
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </TypographyStyled>
        </InformationPage>
    );
};

export default Contact;
