import React from 'react';
import InformationPage from "../components/InformationPage";
import {TypographyStyled} from "../components/CarPostList";
import {emailAddress, mailTo} from "./Contact";

const About: React.FC = () => {
    document.title = "Rreth Nesh - AutoShqip"

    return (
        <InformationPage>
            <h2>Rreth nesh</h2>
            <TypographyStyled>
                Ne jemi këtu per t'ju ndihmuar të kërkoni, blini dhe shisni makina në Shqipëri duke i marrë të gjitha postimet
                nga faqet e Instagramit dhe interneti dhe duke i bashkuar
                këtu per ju.
                <br/>
                <br/>
                Në këtë mënyrë, nuk keni pse i kontrolloni 1 nga 1 nëpër Instagram ose website të tjera, por ejani në Auto Shqip dhe i keni të gjitha gati -- madje me filtra 100% funksional, të kuruar ne detaj.
                <br/>
                <br/>
                Do të vazhdojme të shtojmë opsione të reja si dhe të rregullojmë çdo problem që mund t'ju shfaqet.
                <br/>
                <br/>
                Për çdo informacion, shqetësim apo vërejtje mund të na kontaktoni në faqen e Instagramit (ndodhet më poshtë) ose në e-mail:
                <br/>
                <br/>
                <a href={mailTo}>
                    <strong>{emailAddress}</strong>
                </a>
                <br/>
                <br/>
                Ju falenderojmë.
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </TypographyStyled>
        </InformationPage>
    );
};

export default About;
